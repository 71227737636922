






















































































































































































import { computed, defineComponent } from "@vue/composition-api";
// import gsap from "gsap";
// import { throttle } from "lodash";
import APP_CONFIG from "@/apps/core/modules/config";
import accountStateRef, {
  isAdmin,
  isKonsulen,
  isResiden,
} from "@/apps/accounts/modules/store";
import { isMobile } from "../modules/store";
import router from "@/router";

export default defineComponent({
  name: "Navbar",
  setup() {
    const nama = computed(() => accountStateRef.me.anggota?.nama ?? "");
    const isHome = computed(() => router.currentRoute.name === "home");

    // let actionNav: gsap.core.Tween | null = null;
    // let lastScrollTop = 0;
    // onUpdated(() => {
    //   actionNav = gsap.to(".mobile-header", {
    //     y: "-=60",
    //     duration: 0.5,
    //     ease: "power2.in",
    //     paused: true,
    //   });
    // });

    // const adjustMobileHeader = () => {
    //   const st = window.pageYOffset || document.documentElement.scrollTop;
    //   if (st > lastScrollTop) {
    //     actionNav?.play();
    //   } else {
    //     actionNav?.reverse();
    //   }
    //   lastScrollTop = st <= 0 ? 0 : st;
    // };

    // window.addEventListener("scroll", throttle(adjustMobileHeader, 500), { passive: true });

    return {
      // Data
      brandText: APP_CONFIG.brandText,
      subBrandText: APP_CONFIG.subBrandText,
      accountStateRef,

      // Computed
      isAdmin,
      isHome,
      isKonsulen,
      isMobile,
      isResiden,
      nama,

      // Method
      logout: () => {
        accountStateRef.me.logout();
        router.push({ name: "login" });
      },
    };
  },
});
